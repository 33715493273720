import UnitsList from "components/dashboard/units/UnitsList";

export default function Units() {
    return (
      <>
        <h1 className="dashboardPageTitle">Cleaning List</h1>

        {/* Filters */}

        {/* List of units */}
        <ul className="listOfUnits p-3">
          <li className="my-3 headerRow">

            <div className="row px-3 justify-content-between">
              <div className="col-md-3 bold text-center text-md-left">Space</div>
              <div className="col-md-2 bold textCentre d-none d-md-block">Type</div>
              <div className="col-md-2 bold textCentre">Status</div>
              <div className="col-lg-3 bold textCentre d-none d-lg-block">Checked By</div>
              <div className="col-md-1 bold textCentre"><i className="fa-solid fa-triangle-exclamation"></i></div>
              {/* <div className="col-md-1 bold textCentre d-none d-md-block"><i className="fa-solid fa-plus "></i></div> */}
            </div>
            
          </li>
          <div className="listContent">
            <UnitsList />
          </div>
        </ul>
    </>
    );
  }