import { useState } from "react";

const HeadlineMetrics = (props) => {

    const [siteId] = useState(props.siteId)

    return (
        <div className="row py-1 justify-content-between">
            <div className="col-md py-3 my-3 headlineMetric">
                <h4 className="headlineMetricDescription">Total cleans</h4>
                <a href="dashboard/units" className="headlineMetricValueLink">10</a>
            </div>
            <div className="col-md py-3 my-3 headlineMetric">
                <h4 className="headlineMetricDescription">In Progress</h4>
                <a href="dashboard/units" className="headlineMetricValueLink">10</a>
            </div>
            <div className="col-md py-3 my-3 headlineMetric">
                <h4 className="headlineMetricDescription">Complete</h4>
                <a href="dashboard/units" className="headlineMetricValueLink">10</a>
            </div>
            <div className="col-md-3 py-3 my-3 headlineMetric">
                <h4 className="headlineMetricDescription">Cleaners Code</h4>
                <span className="headlineMetricValue">948184</span>
          </div>
        </div>
    );
}

export default HeadlineMetrics;