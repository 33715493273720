import CleaningList from "components/cleaning/CleaningList";

export default function Cleaning() {
    return (
      <div className="container-md cleaning py-2 px-3">
        {/* Dropdown and Logout */}
        <div className="d-flex flex-row justify-content-between align-bottom header">
          <span className="siteNameTitle">Kavanagh Court</span>
          <span className="logout">
            <i className="fa-solid fa-arrow-right-from-bracket"></i> 
          </span>
        </div>
        <div className="scrollableContent">
          <div className="welcomeMessage my-3">
            Hello, Daniel Crehan!
          </div>
          {/* Notifications */}

          {/* Seach and Filters */}

          {/* Cleaning List */}
          <CleaningList />
        </div>
      </div>
    );
  }