import HeadlineMetrics from 'components/dashboard/overview/HeadlineMetrics';
import AlertsOverview from 'components/dashboard/overview/AlertsOverview';
import CleanersOverview from 'components/dashboard/overview/CleanersOverview';

export default function Overview() {
    return (
      
      <>
      <h1 className="dashboardPageTitle">Cleaning Overview</h1>
        
      <HeadlineMetrics />

      <div className="row justify-content-between">
        <AlertsOverview />
        <CleanersOverview />
      </div>
      </>
    );
  }