import { useState } from "react";

const OccupancyStatus = (props) => {

    const [occupied] = useState(props.occupied)

    return (
        <span className="occupancyStatus">
            <div className={"pulseCircle mx-1 " + (occupied ? "redBG" : "greenBG")}></div>
            <span className={occupied ? "redText" : "greenText"}>{occupied ? "Occupied" : "Empty"}</span>
        </span>
    );
}

export default OccupancyStatus;