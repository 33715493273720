import { useState } from "react";

const ActionsModal = (props) => {

    const [data] = useState(props.data)

    return (
        <>
        <button type="button" className="actions my-1" data-bs-toggle="modal" data-bs-target={"#actionModal" + (data.cleaningTaskId)}>
            <i className="fa-solid fa-plus fa-2xl"></i>
        </button>

        <div className="modal fade" id={"actionModal" + (data.cleaningTaskId)} tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog cleaningTaskModal mx-auto">
                <div className="modal-content cleaningTaskModalContent">
                    <div className="modal-footer justify-content-between">
                        <div className="cleaningTaskModalUnitName">{data.unit}</div>
                        <div className="buttonGroup">
                            {/* <button type="button" className="btn btn-purple" data-bs-dismiss="modal">Close</button> */}

                            <button type="button" className="btn btn-green">{data.cleaningStatus === "not started" ? "Start" : "Complete"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default ActionsModal;