

const CleanersOverview = (props) => {

    const data =  {
        "cleaners": [
            {
                "name": "Alfred Acorn", 
                "supervisor": false
            },
            {
                "name": "Brendon Butterfly",
                "supervisor": true
            },
            {
                "name": "Charlie Cauliflower",
                "supervisor": false
            },
            {
                "name": "Daniel Duvet",
                "supervisor": true
            },
            {
                "name": "Earl Ekhart",
                "supervisor": false
            }
        ]
    }

    return (
        <div className="col-lg-3 py-3 my-3 cleanersOverview">
            <h4>Housekeepers On-site</h4>
            <ul className="cleanersListOverview">

                {
                    data.cleaners.map( (cleaner,i ) => {
                        return <li key={i} className={cleaner.supervisor ? 'cleanersListOverviewSupervisor' : ''}>{cleaner.name}</li>
                    })
                }
            </ul>
          </div>
    );
}

export default CleanersOverview;