import './App.css';
import {
  BrowserRouter,
  Link
} from "react-router-dom";

export default function App() {
  return (
    // <BrowserRouter>
    <div className='d-flex flex-column container-fluid text-center align-items-center align-items-middle loginContainer py-5'>
      
        {/* <Link to="/cleaning">Cleaning</Link> 
         */}
      <form className='my-auto'>
        <h1 className=''>Glan</h1>
        <label for="cleanerCodeInput" className='form-label'>Enter cleaners code:</label>
        <input id="cleanerCodeInput" className="form-control" type="text" minLength={6} maxLength={6} placeholder={123456}></input>
        <label for="cleanerNameInput" className='form-label'>Enter your name:</label>
        <input id="cleanersNameInput" className='form-control' type="text" minLength={3} placeholder="John Smith"></input>
        <button type="submit" className='btn btn-login mt-3'>Submit</button>
      </form>
      <div className='mt-atuo'>
      <Link to="/dashboard">Staff Log In</Link>
      </div>
    </div>
    // </BrowserRouter>
  );
}
