import Cleaner from 'components/dashboard/cleaners/Cleaner';

const CleanersList = () => {
    return (
        <>
        <Cleaner />
        <Cleaner />
        <Cleaner />
        <Cleaner />
        <Cleaner />
        <Cleaner />
        <Cleaner />
        </>

    );
}

export default CleanersList