import AlertModal from "components/dashboard/alerts/AlertModal";
import { useState } from "react";
const Unit = (props) => {

    const [data] = useState(props.data)

    // const data = {
    //     "unitName": "A1.1B",
    //     "cleaningType": "checkout",
    //     "cleaningStatus": "In Progress",
    //     "checkedBy": "Daniel C.",
    //     "hasAlert": true,
    //     "canBeAdded": false,
    //     "alert": {
    //         "id": "123531",
    //         "unit": "A1.1B",
    //         "title": "Guest in room",
    //         "notes": "This is a note about this alert that I have written"
    //     }
    // }
    var cleaningStatusBorderClass;
    switch (data.cleaningStatus) {
        case "not started":
            cleaningStatusBorderClass = "notStarted";
            break;
        case "in progress":
            cleaningStatusBorderClass = "inProgress";
            break;
        case "complete":
            cleaningStatusBorderClass = "complete";
            break;
        default:
            cleaningStatusBorderClass = "";
    }

    return (
        <li className={"my-3 unitItem " + cleaningStatusBorderClass}>
            <div className="row px-3 justify-content-between">
                <div className="col-md-3 bold text-center text-md-left text-truncate">{data.unit}</div>
                <div className="col-md-2 textCentre d-none d-md-block">{data.cleaningType}</div>
                <div className="col-md-2 textCentre">{data.cleaningStatus}</div>
                <div className="col-lg-3 textCentre d-none d-lg-block">{data.cleanedBy}</div>
                <div className="col-md-1 textCentre">
                {
                    
                    data.alert ? <AlertModal key={data.alert.id} alertInfo={data.alert} /> : ""
                    
                }
                </div>
                
                {/* <div className="col-md-1 textCentre d-none d-md-block">
                {
                    data.canBeAdded ? <i className="fa-solid fa-plus"></i> : ""
                }
                </div> */}
            </div>
        </li>
    );
}

export default Unit;