
const Cleaner = () => {

    const data = {
        "name": "Daniel Crehan",
        "signInTime": "17-04-22 12:22",
        "signOutTime": "-",
        "keyNumber": "123521",
        "isSupervisor": true
    }
    return (
        <li className="my-3 unitItem">
            <div className="row px-3 justify-content-between">
                <div className={"col-md-5 bold text-center text-md-left text-truncate " + (data.isSupervisor ? "cleanersListOverviewSupervisor" : "")}>{data.name}</div>
                <div className="col-md-2 textCentre d-none d-md-block">{data.signInTime}</div>
                <div className="col-md-2 textCentre">{data.signOutTime}</div>
                <div className="col-lg-2 textCentre d-none d-lg-block">{data.keyNumber}</div>
                <div className="col-md-1 textCentre d-none d-md-block">
                {
                    data.canBeAdded ? <i className="fa-solid fa-plus"></i> : ""
                }
                </div>
            </div>
        </li>
    );
}

export default Cleaner