import { useState } from "react";

const AlertModal = (props) => {

    const testData = {
        "id": "123531",
        "unit": "A1.1 Kitchen",
        "title": "Guest in room",
        "notes": "This is a note about this alert that I have written"
    }

    const [data] = useState(props.alertInfo);

    return (
        <>
        <button type="button" className="btnAlert" data-bs-toggle="modal" data-bs-target={"#alertModal" + (data.id)}>
            <i className="fa-solid fa-triangle-exclamation btnAlert"></i>
        </button>

        <div className="modal fade" id={"alertModal" + (data.id)} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <span className="modal-title" id="exampleModalLabel">Alert - {data.unit}</span>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="alertInfoTitle text-left">
                            {data.title}
                        </div>
                        <form class="alertInfoForm">
                            <div className="mb-3">
                                <label for="description-text" className="col-form-label">Notes:</label>
                                <textarea className="form-control" id="description-text">{data.notes}</textarea>
                            </div>
                            <button type="button" className="btn btn-green float-end">Update</button>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-purple" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-green">Mark Resolved</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default AlertModal