
const Header = (props) => {


    return (
        <div className="row mb-5 justify-content-between" id="headerRow">
            <div className="col-md-7">
          
                <div className="dropdown" id="siteSelector">
                    <button className="btn btn-lg dropdown-toggle" type="button" id="siteSelectorButton" data-bs-toggle="dropdown" aria-expanded="false">
                        Kavanagh Court
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="siteSelectorButton">
                        <li><a className="dropdown-item" href="#">Arran House</a></li>
                        <li><a className="dropdown-item" href="#">Ardcairn House</a></li>
                        <li><a className="dropdown-item" href="#">Canal Point</a></li>
                    </ul>
                </div>

            </div>
          
            <div className="col-md-2 d-none d-md-flex" id="printIcon">
                <i className="fa-solid fa-print fa-2xl"></i>
            </div>
            <div className="col-md-3 d-none d-md-flex" id="search">
                <i className="fa-solid fa-magnifying-glass mx-1"></i>
                <input type="search" placeholder="Search..." name="q"></input>
            </div>
        </div>
    );
};

export default Header;