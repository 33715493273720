import AlertsList from "components/dashboard/alerts/AlertsList";

export default function Alerts() {
    return (
      <>
      <h1 className="dashboardPageTitle">Alerts</h1>

      {/* Filters */}

      {/* List of units */}
      <ul className="listOfUnits p-3">
        <li className="my-3 headerRow">

          <div className="row px-3 justify-content-between">
            <div className="col-md-2 bold text-center text-md-left">Space</div>
            <div className="col-md-4 bold textCentre d-none d-md-block">Title</div>
            <div className="col-lg-4 bold textCentre d-none d-lg-block">Description</div>
            <div className="col-md-1 bold textCentre"><i className="fa-solid fa-triangle-exclamation"></i></div>
          </div>
          
        </li>
        <div className="listContent">
          <AlertsList />
        </div>
      </ul>
    </>
    );
  }