import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { 
  BrowserRouter,
  Routes,
  Route 
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

//IMPORT ROUTE COMPONENTS
import Cleaning from './routes/cleaning';
import Dashboard from './routes/dashboard';
import NotFound from './routes/notfound';
import Alerts from './routes/alerts';
import Overview from './routes/overview';
import Cleaners from './routes/cleaners';
import Units from './routes/units';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="dashboard" element={<Dashboard />}>
        <Route index element={<Overview />} />
        <Route path="units" element={<Units />} />
        <Route path="cleaners" element={<Cleaners />} />
        <Route path="alerts" element={<Alerts />} />
      </Route>
      <Route path="cleaning" element={<Cleaning />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
