import Alert from 'components/dashboard/alerts/Alert';

const AlertsList = () => {

    const data = {
        "cleaningTasks": [
            {
                "cleaningTaskId": "1",
                "unit": "A1.1A",
                "cleaningType": "checkout",
                "occupied": true,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan",
                "alert": {
                    "id": "1",
                    "unit": "A1.1A",
                    "title": "Guest in room",
                    "notes": "This is a note about this alert that I have written"
                }
            },
            {
                "cleaningTaskId": "2",
                "unit": "A1.1B",
                "cleaningType": "refresh",
                "occupied": true,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan",
                "alert": {
                    "id": "2",
                    "unit": "A1.1B",
                    "title": "Guest in room",
                    "notes": "This is a note about this alert that I have written"
                }
            },
            {
                "cleaningTaskId": "3",
                "unit": "A1.1C",
                "cleaningType": "checkout",
                "occupied": false,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan",
                "alert": {
                    "id": "3",
                    "unit": "A1.1C",
                    "title": "Guest in room",
                    "notes": "This is a note about this alert that I have written"
                }
            },
            {
                "cleaningTaskId": "4",
                "unit": "A1.1D",
                "cleaningType": "checkout",
                "occupied": true,
                "cleaningStatus": "in progress",
                "cleanedBy": "Daniel Crehan",
                "alert": {
                    "id": "4",
                    "unit": "A1.1D",
                    "title": "Guest in room",
                    "notes": "This is a note about this alert that I have written"
                }
            },
            {
                "cleaningTaskId": "5",
                "unit": "A1.1 Kitchen",
                "cleaningType": "kitchen",
                "occupied": false,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan",
                "alert": {
                    "id": "5",
                    "unit": "A1.1 Kitchen",
                    "title": "Guest in room",
                    "notes": "This is a note about this alert that I have written"
                }
            },
            {
                "cleaningTaskId": "6",
                "unit": "A2.1A",
                "cleaningType": "refresh",
                "occupied": true,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan",
                "alert": {
                    "id": "6",
                    "unit": "A2.1A",
                    "title": "Guest in room",
                    "notes": "This is a note about this alert that I have written"
                }
            },
            {
                "cleaningTaskId": "7",
                "unit": "A2.1B",
                "cleaningType": "refresh",
                "occupied": true,
                "cleaningStatus": "complete",
                "cleanedBy": "Daniel Crehan",
                "alert": {
                    "id": "7",
                    "unit": "A2.1B",
                    "title": "Guest in room",
                    "notes": "This is a note about this alert that I have written"
                }
            },
            {
                "cleaningTaskId": "8",
                "unit": "A2.1C",
                "cleaningType": "checkout",
                "occupied": false,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan",
                "alert": {
                    "id": "8",
                    "unit": "A2.1C",
                    "title": "Guest in room",
                    "notes": "This is a note about this alert that I have written"
                }
            },
            {
                "cleaningTaskId": "9",
                "unit": "A2.1 Kitchen",
                "cleaningType": "kitchen",
                "occupied": false,
                "cleaningStatus": "in progress",
                "cleanedBy": "Daniel Crehan",
                "alert": {
                    "id": "9",
                    "unit": "A2.1 Kitchen",
                    "title": "Guest in room",
                    "notes": "This is a note about this alert that I have written"
                }
            },
            {
                "cleaningTaskId": "10",
                "unit": "A2.2A",
                "cleaningType": "checkout",
                "occupied": true,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan",
                "alert": {
                    "id": "10",
                    "unit": "A2.2A",
                    "title": "Guest in room",
                    "notes": "This is a note about this alert that I have written"
                }
            },
            {
                "cleaningTaskId": "11",
                "unit": "A2.2B",
                "cleaningType": "checkout",
                "occupied": false,
                "cleaningStatus": "complete",
                "cleanedBy": "Daniel Crehan",
                "alert": {
                    "id": "11",
                    "unit": "A2.2B",
                    "title": "Guest in room",
                    "notes": "This is a note about this alert that I have written"
                }
            },
            {
                "cleaningTaskId": "12",
                "unit": "A2.2C",
                "cleaningType": "refresh",
                "occupied": true,
                "cleaningStatus": "in progress",
                "cleanedBy": "Daniel Crehan",
                "alert": null
            },
            {
                "cleaningTaskId": "13",
                "unit": "A2.2 Kitchen",
                "cleaningType": "kitchen",
                "occupied": false,
                "cleaningStatus": "complete",
                "cleanedBy": "Daniel Crehan",
                "alert": {
                    "id": "13",
                    "unit": "A2.2 Kitchen",
                    "title": "Guest in room",
                    "notes": "This is a note about this alert that I have written"
                }
            }
        ]
    }

    return (
        <>
        {
            data.cleaningTasks.map( (item) => {
                if (item.alert){
                    return <Alert data={item.alert} />
                } 
                return ""
            })
        }
        </>
    );

}

export default AlertsList;