import CleanersList from 'components/dashboard/cleaners/CleanersList';

export default function Cleaners() {
    return (
      <>
      <h1 className="dashboardPageTitle">Housekeepers</h1>

      {/* Filters */}

      {/* List of units */}
      <ul className="listOfUnits p-3">
        <li className="my-3 headerRow">

          <div className="row px-3 justify-content-between">
            <div className="col-md-5 bold text-center text-md-left">Name</div>
            <div className="col-md-2 bold textCentre d-none d-md-block">Sign In</div>
            <div className="col-md-2 bold textCentre">Sign Out</div>
            <div className="col-lg-2 bold textCentre d-none d-lg-block">Key Number</div>
            <div className="col-md-1 bold textCentre d-none d-md-block"><i className="fa-solid fa-plus "></i></div>
          </div>
          
        </li>
        <div className="listContent">
          <CleanersList />
        </div>
      </ul>
    </>
    );
  }