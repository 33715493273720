import Alert from 'components/dashboard/overview/Alert';

const AlertsOverview = () => {
    var tmpData = {
        "unit": "1.1",
        "title": "Guest in Room",
        "time" : "3 minutes ago"
    }

    const data = {
        "alerts": [
        {
            "unit": "1.1",
            "title": "Guest in Room",
            "time" : "3 minutes ago"
        },
        {
            "unit": "1.2",
            "title": "Guest in Room",
            "time" : "3 minutes ago"
        },
        {
            "unit": "1.3",
            "title": "Guest in Room",
            "time" : "3 minutes ago"
        },
        {
            "unit": "1.4",
            "title": "Guest in Room",
            "time" : "3 minutes ago"
        },
        {
            "unit": "1.5",
            "title": "Guest in Room",
            "time" : "3 minutes ago"
        }]
    
    }

    return (
        <div className="col-lg py-3 my-3 alertsOverview">
        <h4>Alerts</h4>
        <div className="row alertsOverviewContainer">
          <div className="col-lg-4 mb-2">
            <div className="alertOverview new">
              <h4>Create Alert</h4>
              <a href="#" className="createNewAlertOverview"><i className="fa-solid fa-plus fa-2xl my-auto"></i></a>
            </div>
          </div>

          {
            data.alerts.map( (alert,i) => {
                return <Alert key={i} alertData={alert} />
            })
          }

        </div>
      </div>
    );
}


export default AlertsOverview;