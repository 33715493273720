import React, { useState } from "react";

const Sidebar = (props) => {
    const [currentTab] = useState(props.currentTab);

    return (
        <div className="col-md-auto sticky-top sidebar mx-auto" id="sidebar">
              <div className="d-flex flex-md-column flex-row flex-nowrap align-items-center sticky-top mx-auto">
                  <a href="/" className="p-3 text-decoration-none logo d-none d-md-block" title="Home" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Icon-only">
                      Glan
                  </a>
                  <ul className="nav nav-pills nav-flush flex-md-column flex-row flex-nowrap  mx-auto text-center justify-content-between w-100 px-3 align-items-center">
                      <li className="nav-item">
                          <a href="/dashboard" className="nav-link py-md-3 px-md-2" title="Overview" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Home">
                            <i className={"fa-solid fa-chart-simple fa-2xl " + (currentTab === "dashboard" ? 'iconActive' : 'iconInactive')}></i>
                          </a>
                      </li>
                      <li className="nav-item">
                          <a href="/dashboard/units" className="nav-link py-md-3 px-md-2" title="Units" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Home">
                            <i className={"fa-solid fa-bed fa-2xl " + (currentTab === "units" ? 'iconActive' : 'iconInactive')}></i>
                          </a>
                      </li>
                      <li>
                          <a href="/dashboard/cleaners" className="nav-link py-md-3 px-md-2" title="Cleaners" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
                            <i className={"fa-solid fa-users fa-2xl " + (currentTab === "cleaners" ? 'iconActive' : 'iconInactive')}></i>
                          </a>
                      </li>
                      <li>
                          <a href="/dashboard/alerts" className="nav-link py-md-3 px-md-2" title="Alerts" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Orders">
                            <i className={"fa-solid fa-triangle-exclamation fa-2xl "+ (currentTab === "alerts" ? 'iconActive' : 'iconInactive')}></i>
                          </a>
                      </li>
                  </ul>
                  <div className="my-auto">
                    <a href="#" className="d-block p-3 link-dark text-decoration-none" title="Logout" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Icon-only">
                      <i className="fa-solid fa-arrow-right-from-bracket fa-2xl iconInactive"></i> 
                    </a>
                  </div>
                  
              </div>
          </div>
    );
}

export default Sidebar;