import OccupancyStatus from "components/cleaning/OccupancyStatus";
import ActionsModal from "components/cleaning/ActionsModal";

import { useState } from "react";

const CleaningItem = (props) => {
    const refreshIcon = 'fa-solid fa-arrows-rotate'
    const checkoutIcon = 'fa-solid fa-right-from-bracket'
    const kitchenIcon = 'fa-solid fa-kitchen-set'

    const [data] = useState(props.data)
    var icon, cleaningStatusBorderClass;

    switch (data.cleaningType) {
        case "checkout":
            icon = checkoutIcon;
            break;
        case "kitchen":
            icon = kitchenIcon;
            break;
        case "refresh":
            icon = refreshIcon;
            break;
        default:
            icon = checkoutIcon;
    }

    switch (data.cleaningStatus) {
        case "not started":
            cleaningStatusBorderClass = "notStarted";
            break;
        case "in progress":
            cleaningStatusBorderClass = "inProgress";
            break;
        case "complete":
            cleaningStatusBorderClass = "complete";
            break;
        default:
            cleaningStatusBorderClass = "";
    }


    return (
        <div className={"cleaningItem p-1 my-2 " + cleaningStatusBorderClass}>
            <div className="d-flex flex-row">
                <div className="cleaningTypeIcon">
                    <i className={icon + " fa-5x mx-3"}></i>
                </div>
                <div className="d-flex flex-column w-100">
                    <span className="unitName">{data.unit}</span>
                    <span className="cleaningType">{data.cleaningType} Clean</span>
                    <div className="d-flex flex-row justify-content-between">
                        <OccupancyStatus occupied={data.occupied}/>
                        <span className="cleaningStatus">{data.cleaningStatus}</span>
                    </div>
                </div>
            </div>
            <ActionsModal data={data}/>
            {/* <i className="fa-solid fa-plus fa-2xl actions my-3 mx-1"></i> */}
        </div>
    );
}

export default CleaningItem;