import { Outlet, useLocation } from "react-router-dom";
import Sidebar from 'components/dashboard/Sidebar';
import Header from 'components/dashboard/Header';

export default function Dashboard() {
    const location = useLocation();
    const finalPath = location.pathname.split("/").pop()

    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar currentTab={finalPath} />

          <div className="col-md p-3 min-vh-100 content">
            <Header />
            <Outlet />
          </div>
            
        </div>
      </div>
    );
  }