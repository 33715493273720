import { useState, setState } from "react";

const Alert = (props) => {

    const [alertData] = useState(props.alertData)

    return (
        <div className="col-lg-4 mb-2">
            <div className="alertOverview">
                <span className="maximise"><a href="dashboard/alerts"><i className="fa-solid fa-maximize fa-xl"></i></a></span>
                <span className="alertOverviewUnit">{alertData.unit}</span>
                <span className="alertOverviewTitle">{alertData.title}</span>
                <span className="alertOverviewWhen">{alertData.time}</span>
            </div>
        </div>
    );
}

export default Alert;