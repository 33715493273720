import CleaningItem from "components/cleaning/CleaningItem";

const CleaningList = () => {

    const data = {
        "cleaningTasks": [
            {
                "cleaningTaskId": "1",
                "unit": "A1.1A",
                "cleaningType": "checkout",
                "occupied": true,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan"
            },
            {
                "cleaningTaskId": "2",
                "unit": "A1.1B",
                "cleaningType": "refresh",
                "occupied": true,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan"
            },
            {
                "cleaningTaskId": "3",
                "unit": "A1.1C",
                "cleaningType": "checkout",
                "occupied": false,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan"
            },
            {
                "cleaningTaskId": "4",
                "unit": "A1.1D",
                "cleaningType": "checkout",
                "occupied": true,
                "cleaningStatus": "in progress",
                "cleanedBy": "Daniel Crehan"
            },
            {
                "cleaningTaskId": "5",
                "unit": "A1.1 Kitchen",
                "cleaningType": "kitchen",
                "occupied": false,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan"
            },
            {
                "cleaningTaskId": "6",
                "unit": "A2.1A",
                "cleaningType": "refresh",
                "occupied": true,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan"
            },
            {
                "cleaningTaskId": "7",
                "unit": "A2.1B",
                "cleaningType": "refresh",
                "occupied": true,
                "cleaningStatus": "complete",
                "cleanedBy": "Daniel Crehan"
            },
            {
                "cleaningTaskId": "8",
                "unit": "A2.1C",
                "cleaningType": "checkout",
                "occupied": false,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan"
            },
            {
                "cleaningTaskId": "9",
                "unit": "A2.1 Kitchen",
                "cleaningType": "kitchen",
                "occupied": false,
                "cleaningStatus": "in progress",
                "cleanedBy": "Daniel Crehan"
            },
            {
                "cleaningTaskId": "10",
                "unit": "A2.2A",
                "cleaningType": "checkout",
                "occupied": true,
                "cleaningStatus": "not started",
                "cleanedBy": "Daniel Crehan"
            },
            {
                "cleaningTaskId": "11",
                "unit": "A2.2B",
                "cleaningType": "checkout",
                "occupied": false,
                "cleaningStatus": "complete",
                "cleanedBy": "Daniel Crehan"
            },
            {
                "cleaningTaskId": "12",
                "unit": "A2.2C",
                "cleaningType": "refresh",
                "occupied": true,
                "cleaningStatus": "in progress",
                "cleanedBy": "Daniel Crehan"
            },
            {
                "cleaningTaskId": "13",
                "unit": "A2.2 Kitchen",
                "cleaningType": "kitchen",
                "occupied": false,
                "cleaningStatus": "complete",
                "cleanedBy": "Daniel Crehan"
            }
        ]
    }

    return (
        <div className="cleaningList">
            {
                data.cleaningTasks.map( (item) => {
                    return <CleaningItem key={item.cleaningTaskId} data={item} />
                })
            }
        </div>
    );

}

export default CleaningList;