import AlertModal from "components/dashboard/alerts/AlertModal";
import { useState } from "react";

const Alert = (props) => {

    const [data] = useState(props.data)

    // const data = {
    //     "id": "123531",
    //     "unit": "A1.1A",
    //     "title": "Guest in room",
    //     "notes": "This is a note about this alert that I have written"
    // }

    return (
        <li className="my-3 unitItem">
            <div className="row px-3 justify-content-between">
                <div className="col-md-2 bold text-center text-md-left text-truncate">{data.unit}</div>
                <div className="col-md-4 bold textCentre d-none d-md-block text-truncate">{data.title}</div>
                <div className="col-lg-4 bold textCentre d-none d-lg-block text-truncate alertListNotes">{data.notes}</div>
                <div className="col-md-1 bold textCentre">
                    <AlertModal alertInfo={data}/>
                </div>
            </div>
        </li>
    );
}

export default Alert;